import React from 'react';
import '@fontsource/prata';
import '@fontsource/average';
import { LocationProvider } from './src/context/LocationContext';

export const wrapPageElement = ({ element, props }) => {
  const { location } = props;

  return (
    <LocationProvider location={location}>
      {element}
    </LocationProvider>
  );
};
